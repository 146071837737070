import React from "react";

const AuthorIcon = () => {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 22C1 17.4897 4.65634 13.8333 9.16667 13.8333C9.56275 13.8333 9.9523 13.8616 10.3333 13.916M13.8333 5.66667C13.8333 8.244 11.7439 10.3333 9.16667 10.3333C6.58934 10.3333 4.5 8.244 4.5 5.66667C4.5 3.08934 6.58934 1 9.16667 1C11.7439 1 13.8333 3.08934 13.8333 5.66667ZM11.0214 22L13.3839 21.5275C13.59 21.4863 13.693 21.4657 13.789 21.428C13.8743 21.3946 13.9552 21.3512 14.0304 21.2988C14.115 21.2398 14.1893 21.1656 14.3378 21.017L19.1881 16.1667C19.8324 15.5223 19.8324 14.4777 19.1881 13.8333C18.5439 13.189 17.4991 13.189 16.8548 13.8333L12.0045 18.6836C11.8559 18.8323 11.7816 18.9065 11.7226 18.9912C11.6702 19.0662 11.6269 19.1473 11.5934 19.2325C11.5558 19.3286 11.5352 19.4316 11.4939 19.6375L11.0214 22Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AuthorIcon;
