const { default: instance } = require("utils/axios");

class RuleService {
  async getAllRule() {
    const { data } = await instance.get("/rule");
    return data;
  }
  async createRule({index, title, body}) {
    const { data } = await instance.post("/rule/newRule", {
      index,
      title,
      body,
    });
    return data;
  }

  async deleteRule(id) {
    const { data } = await instance.delete(`/rule/${id}`);
    return data;
  }
}

export const ruleService =  new RuleService();
