import React from "react";
import styles from "./Description.module.css";
const Description = () => {
  return (
    <div className={styles.center}>
      <div className={styles.items}>
      <div className={styles.images}
        >
          Обложка
        </div>
        <div className={styles.name}
        >
          Название
        </div>
        <div className={styles.item}>
          <div>Автор</div>
          <div>Жанры</div>
        </div>
        <div className={styles.item_two}>
          <div>Кол-во показов</div>
          <div>Доступно к выплате</div>
          <div>Выплачено</div>
          <div>Заработано</div>
        </div>
        <div className={styles.status}>Статус публикации</div>
      </div>
    </div>
  );
};

export default Description;
