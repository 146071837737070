import React, { useEffect, useRef, useState } from "react";
import styles from "./SortPopular.module.css";
import { IoIosArrowDown } from "react-icons/io";
const SortPopular = ({ items, setFilter, count, setCount, isDesc, setIsDesc }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = (i) => {
		setCount(i)
		setFilter(items[i].filter)
		setIsOpen(false)
	};
	const modalRef = useRef(null)
	useEffect(() => {
		const handleClick = (e) => {
			const path = e.path || (e.composedPath && e.composedPath())
			if (!path.includes(modalRef.current)) {
				setIsOpen(false)
			}
		}
		document.body.addEventListener('click', handleClick)
	return () => {
			document.body.removeEventListener('click', handleClick)
		}
	}, [])
  return (
    <div className={styles.wrapper} ref={modalRef}>
      <div className={styles.heading}>
        <h3>Сортировать по:</h3>
        <p onClick={() => setIsOpen(!isOpen)}>{items[count].name}</p>
        <IoIosArrowDown
          className={isDesc && styles.desc}
          onClick={() => setIsDesc(!isDesc)}
        />
      </div>

      {isOpen && (
        <div className={styles.items}>
          {items.map((item, i) => (
            <div key={item.filter}
              onClick={() => onClick(i)}
              className={`${styles.option} ${count === i && styles.active}`}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortPopular;
