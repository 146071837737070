import img from "assets/bookva-512x512.png";
import { NavModalContext } from "providers/NavModalProvider";
import { useContext } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useSize } from "utils/useSize";
import styles from "./Header.module.css";

import { IoMdClose } from "react-icons/io";

const Header = () => {
  const { isOpen, setIsOpen } = useContext(NavModalContext);
  const { width } = useSize();
  return (
    <>
      {width >= 1100 ? (
        <></>
      ) : (
        <div className={styles.header}>
          <div>
            <img src={img} alt="" />
          </div>
          <div onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <IoMdClose /> : <RxHamburgerMenu />}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
