import React from "react";
import styles from "./Paginiton.module.css";
import { GoChevronLeft } from "react-icons/go";
import { GoChevronRight } from "react-icons/go";

const Pagination = ({ totalPages, currentPage, onChangePage }) => {
  const handleClick = (page) => {
    if (page !== currentPage && page >= 1 && page <= totalPages) {
      onChangePage(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const ellipsis = <span key="ellipsis">...</span>;

    if (totalPages <= 9) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <span
            key={i}
            className={currentPage === i ? styles.active : ""}
            onClick={() => handleClick(i)}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 4) {
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(
            <span
              key={i}
              className={currentPage === i ? styles.active : ""}
              onClick={() => handleClick(i)}
            >
              {i}
            </span>
          );
        }
        pageNumbers.push(ellipsis);
        pageNumbers.push(
          <span
            key={totalPages}
            className="page"
            onClick={() => handleClick(totalPages)}
          >
            {totalPages}
          </span>
        );
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(
          <span key={1} className="page" onClick={() => handleClick(1)}>
            1
          </span>
        );
        pageNumbers.push(ellipsis);
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(
            <span
              key={i}
              className={currentPage === i ? styles.active : ""}
              onClick={() => handleClick(i)}
            >
              {i}
            </span>
          );
        }
      } else {
        pageNumbers.push(
          <span key={1} className="page" onClick={() => handleClick(1)}>
            1
          </span>
        );
        pageNumbers.push(ellipsis);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(
            <span
              key={i}
              className={currentPage === i ? styles.active : ""}
              onClick={() => handleClick(i)}
            >
              {i}
            </span>
          );
        }
        pageNumbers.push(ellipsis);
        pageNumbers.push(
          <span
            key={totalPages}
            className="page"
            onClick={() => handleClick(totalPages)}
          >
            {totalPages}
          </span>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className={styles.wrapper}>
      {totalPages ? (
        <>
          <GoChevronLeft
            className={currentPage === 1 ? styles.active : ""}
            onClick={() =>
              onChangePage(currentPage === 1 ? 1 : currentPage - 1)
            }
          />
          <div className={styles.items}>{renderPageNumbers()}</div>
          <GoChevronRight
            className={currentPage === totalPages ? styles.active : ""}
            onClick={() =>
              onChangePage(
                currentPage === totalPages ? totalPages : currentPage + 1
              )
            }
          />{" "}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Pagination;
