import React, { useEffect } from "react";
import styles from "./AdminGenreMax.module.css";
import { IoMdClose } from "react-icons/io";
import { useMutation } from "@tanstack/react-query";
import {adminService} from "service/Admin.service";
import { queryClient } from "index";

const AdminGenreMax = ({ genre, id }) => {
  const { mutate, data, isSuccess } = useMutation({
    mutationKey: ["genresDelte"],
    mutationFn: () => adminService.deleteGenre(id),
  });
  useEffect(() => {
    if (data?.message) alert(data.message);
    if (isSuccess) queryClient.invalidateQueries({ queryKey: ["genre"] });
  }, [data, isSuccess]);
  return (
    <div className={styles.genre}>
      <p>{genre}</p>
      <IoMdClose onClick={() => mutate()} />
    </div>
  );
};

export default AdminGenreMax;
