import React, { useState, useCallback } from "react";
import styles from "./PaymentData.module.css";
import { addDays, format, subDays } from "date-fns";
import { ru } from "date-fns/locale";
import {DateRangePicker} from "../calendar/DateRangePicker";
import { IoCalendarOutline } from "react-icons/io5";
import { checkRole } from "utils/checkRole";

const PaymentData = ({showCount}) => {
 const isAdmin = checkRole()
  const [isOpen, setIsOpen] = useState(false)

  const [valueDateRangePicker, setValueDateRangePicker] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const formattedValueDateRangePickerStartDate = valueDateRangePicker?.[0].startDate
    ? format(valueDateRangePicker[0].startDate, "dd.MM.yyyy", { locale: ru })
    : "";
  const formattedValueDateRangePickerEndDate = valueDateRangePicker?.[0].endDate
    ? format(valueDateRangePicker[0].endDate, "dd.MM.yyyy", { locale: ru })
    : "";

  const handleChangeValueDateRangePicker = useCallback((ranges) => {
    const { selection } = ranges;
    setValueDateRangePicker([selection]);
  }, []);

  return (
    <div className={styles.wrapperPayment}>
      <div className={styles.item}>
        <div className={styles.payment}>
          <p>Общее количество показов рекламы:</p> <h3>{showCount}</h3>
        </div>
        <div className={styles.payment}>
          <p>Заработано всего:</p> <h3>228980</h3>
        </div>
      </div>
      {isAdmin ? '' : <div className={styles.item}>
        <div className={styles.payment}>
          <p>Для выплаты в этом месяце доступно:</p> <h3>228980</h3>
        </div>
        <div className={styles.payment}>
          <p>Выплачено:</p> <h3>228980</h3>
        </div>
      </div>}
			<div className={styles.centerCalendar}>
          <div className={styles.range} onClick={() => setIsOpen(!isOpen)}>
            <IoCalendarOutline/>
            <p>{formattedValueDateRangePickerStartDate === formattedValueDateRangePickerEndDate ? "За весь период " : `${formattedValueDateRangePickerStartDate} - ${formattedValueDateRangePickerEndDate}`}</p>
          </div>
          {isOpen ? 
          <DateRangePicker
          payment={true}
          open = {isOpen}
          close = {setIsOpen}
          editableDateInputs={true}
          locale={ru}
          onChange={handleChangeValueDateRangePicker}
          ranges={valueDateRangePicker}
        /> 
        : "" }
          
        </div>
    </div>
  );
};

export default PaymentData;
