import React, { useEffect, useState } from "react";
import styles from "./AdminCreateAuthor.module.css";
import Layout from "components/layout/Layout";
import Pagination from "components/adminComponents/pagination/Pagination";
import { useMutation, useQuery } from "@tanstack/react-query";
import {adminService} from "service/Admin.service";
import { useSlice } from "utils/useFilter";
import Header from "components/header/Header";
import { queryClient } from "index";
const AdminApprovalAuthor = () => {
  const [pageCount, setPageCount] = useState(1);
  const { data: author } = useQuery({
    queryKey: ["auhorsAppo"],
    queryFn: () => adminService.getAuthorNotApproval(),
  });
  const array = useSlice(author, "author");
  const { mutate, isSuccess } = useMutation({
    mutationKey: ["appAuthor"],
    mutationFn: (id) => adminService.authorApproval(id, true),
  });
  useEffect(() => {
    if (isSuccess) queryClient.invalidateQueries({ queryKey: ["auhorsAppo"] });
  }, [isSuccess]);
  return (
    <Layout>
      <div className={styles.wrapper}>
        <Header />
        <div className={styles.items}>
          {array[pageCount - 1]?.map((item, i) => (
            <div className={styles.wrapperAuthor} key={i}>
              <div className={styles.first}>
                <p>{i + 1}</p>
                <p>{item.name + " " + item.surname}</p>
              </div>
              <button onClick={() => mutate(item.id)}>Одобрить</button>
            </div>
          ))}
        </div>
        <div className={styles.right}>
          <Pagination
            totalPages={array?.length}
            currentPage={pageCount}
            onChangePage={setPageCount}
          />
        </div>
      </div>
    </Layout>
  );
};

export default AdminApprovalAuthor;
