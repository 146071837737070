import AdminAddBook from "pages/Admin/AdminAddBook/AdminAddBook";
import AdminAuthor from "pages/Admin/AdminAuthor/AdminAuthor";
import AdminApprovalAuthor from "pages/Admin/AdminApprovalAuthor/AdminApprovalAuthor";
import AdminGenre from "pages/Admin/AdminGenre/AdminGenre";
import AdminHome from "pages/Admin/AdminHome/AdminHome";
import MyDetails from "pages/MyDetails/MyDetails";
import { Route, Routes } from "react-router-dom";
import AdminCreateAuthor from "pages/Admin/AdminCreateAuthor/AdminCraeteAuthor";
import Rules from "pages/Rules/Rules";

export const AdminRouter = () => {
  return (
    <Routes>
      <Route path="/admin/" element={<AdminHome />} />
      <Route path="/admin/home" element={<AdminHome pagesCount={1} />} />
      <Route path="/admin/myDetails" element={<MyDetails />} />
      <Route path="/admin/author/:id" element={<AdminAuthor />} />
      <Route path="/admin/genre" element={<AdminGenre />} />
      <Route path="/admin/add" element={<AdminAddBook />} />
      <Route path="/admin/approval/author" element={<AdminApprovalAuthor />} />
      <Route path="/admin/add/author" element={<AdminCreateAuthor />} />
			<Route path="/rules" element={<Rules />} />
    </Routes>
  );
};
