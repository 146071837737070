import React, { useEffect, useState } from "react";
import styles from "./AdminGenre.module.css";
import Layout from "components/layout/Layout";
import Input from "components/Base/Input/Input";
import Button from "components/Base/Button/Button";
import Genre from "../AdminGenre/AdminGenreMax/AdminGenreMax";
import { useMutation, useQuery } from "@tanstack/react-query";
import {adminService} from "service/Admin.service";
import Header from "components/header/Header";
import { queryClient } from "index";
const AdminGenre = () => {
  const { mutate, data, isSuccess } = useMutation({
    mutationFn: (value) => adminService.createGenres(value),
  });
  const { data: genres } = useQuery({
    queryKey: ["genre"],
    queryFn: () => adminService.getGenres(),
    refetchInterval: 60000,
  });

  useEffect(() => {
    if (data?.message) alert(data?.message);
		if(isSuccess) queryClient.invalidateQueries({queryKey: ['genre']})
  }, [data, isSuccess]);
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const onClickCreate = () => {
    mutate(value);
  };
  return (
    <Layout>
      <div className={styles.wrapper}>
			<Header />
        <div>
          <header className={styles.header}>
            <p>Жанры</p>
            <Input
              placeholder={"Поиск"}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </header>

          <main className={styles.main}>
            <div className={styles.heading}>
              <Input
                placeholder={"Введите название жанра"}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <Button onClick={() => onClickCreate()} value={"Сохранить"} />
            </div>
            <div className={styles.items}>
              {genres
                ?.filter((item) => {
                  return item.value
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                .map((item, i) => (
                  <Genre genre={item.value} id={item.id} key={i} />
                ))}
            </div>
          </main>
        </div>
      </div>
    </Layout>
  );
};

export default AdminGenre;
