import instance from "../utils/axios";

class AdminService {
  async getAllAuthor() {
    const { data } = await instance.get("/author/");
    return data;
  }

  async getAllBooks() {
    const { data } = await instance.get("/admin/books");
    return data;
  }

  async getGenres() {
    const { data } = await instance.get("/genres");
    return data;
  }

  async createGenres(value) {
    const { data } = await instance.post("/genres", {
      value,
    });
    return data;
  }

  async getAuthor() {
    const { data } = await instance.get("/admin/getAuthor");
    return data;
  }

  async getAuthorNotApproval() {
    const { data } = await instance.get("/admin/getAuthorNotApproval");
    return data;
  }

  async authorApproval(id, status) {
    const { data } = await instance.put("/admin/authorApproval", {
      id,
      status,
    });
    return data;
  }

  async setAuthorProfile(id, propsUpdate) {
    const { data } = await instance.put("/admin/setAuthorProfile", {
      id,
      ...propsUpdate,
    });
    console.log(data);
    return data;
  }

  async deleteGenre(id) {
    const { data } = await instance.delete(`/genres/${id}`);
    return data;
  }

  async getAdminProfile() {
    const { data } = await instance.get("/admin/adminProfile");

    return data;
  }
  async setAdminProfile(profile) {
    const { data } = await instance.put("/admin/setAdminProfile", {
      ...profile,
    });
    return data;
  }
  async deleteAuthor(id) {
    const { data } = await instance.delete(`/admin/deleteAuthor/${id}`);
    console.log(data);
    return data;
  }
}

export const adminService = new AdminService();
