import React, {useEffect} from "react";
import styles from "./UpdateAuthor.module.css";
import PersonalDetailsAdmin from "./PersonalDataAdmin/PresonalDataAdmin";
import { useMutation} from "@tanstack/react-query";
import { adminService } from "service/Admin.service";
import { queryClient } from "index";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "store/modalStore/action";

const UpdateAuthor = ({ id, setIsUpdateMode, status }) => {
  const [, setModalStore] = useModalStore()

  const { mutate, isSuccess } = useMutation({
    mutationKey: ["author"],
    mutationFn: (id) => adminService.authorApproval(id, !status),
  });
  useEffect(() => {
    if (isSuccess) {queryClient.invalidateQueries({ queryKey: ["author"] });
    setIsUpdateMode(false)
    setModalStore(setModal( status ? 'Автор заблокирован!':'Автор разблокирован!'))
  }

  }, [isSuccess, setIsUpdateMode, setModalStore, status]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.blocker}>
          <button onClick={() => mutate(id)}> {status ? "Заблокировать автора" : "Разблокировать автора"}</button>
        </div>
        <PersonalDetailsAdmin id={id} setIsUpdateMode={setIsUpdateMode} />
      </div>
    </div>
  );
};

export default UpdateAuthor;

