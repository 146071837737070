import React from "react";

const BookIcon = () => {
  return (
    <svg
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12.8125V19.375C22 21.8498 22 23.0873 21.2311 23.8561C20.4623 24.625 19.2248 24.625 16.75 24.625H4.28125C2.46907 24.625 1 23.1559 1 21.3438M22 12.8125C22 15.2873 22 16.5248 21.2311 17.2936C20.4623 18.0625 19.2248 18.0625 16.75 18.0625H4.28125C2.46907 18.0625 1 19.5316 1 21.3438M22 12.8125V6.25C22 3.77512 22 2.53769 21.2311 1.76885C20.4623 1 19.2248 1 16.75 1H6.25C3.77512 1 2.53769 1 1.76885 1.76885C1 2.53769 1 3.77512 1 6.25V21.3438"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M7.5625 10.1875L9.25943 11.8844C9.77196 12.397 10.603 12.397 11.1156 11.8844L15.4375 7.5625"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BookIcon;
