import instance from "../utils/axios";

class BookService {
  async getBook() {
    const { data } = await instance.get("/author");
    return data;
  }

  async getBookById(id) {
    const { data } = await instance.get(`/book/get/byId/${id}`, );
    return data;
  }

  async deleteBook(id) {
    const { data } = await instance.delete(`/book/${id}`);
    return data;
  }

  async addBook(formData){
    const { data } = await instance.post(`/book`, {formData})
    return data
  }

  async getShowCount(id){
    const { data } = await instance.get(`/book/showCount/${id}`)
    return data
  }

  async getAdwCount(id){
    const { data } = await instance.get(`/book/showCount/${id}?IS-ADS=true`)
    return data
  }
}

export const bookService = new BookService();
