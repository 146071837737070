import React, { useEffect, useState } from "react";
import styles from "./AdminAddBook.module.css";
import Layout from "components/layout/Layout";
import { BookstoreProvider } from "providers/BookStoreProvider";
import CreateBook from "components/adminComponents/createBook/CreateBook";
import Genre from "components/genre/Genre";
import axios from "axios";
import { baseUrl } from "config";
import Header from "components/header/Header";
const AdminAddBook = () => {
  const [genreShow, setGenreShow] = useState(false);
  const [, setShow] = useState(false);
  const [genres, setGenres] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseUrl}/genres`);
      setGenres(res.data);
    })();
  }, []);
  return (
    <BookstoreProvider>
      <Layout>
        <div className={styles.wrapper}>
					<Header/>
          <CreateBook  genres={genres} setGenreShow={setGenreShow}/>
          {genreShow ? (
            <Genre
              setShow={setShow}
              setGenreShow={setGenreShow}
              genres={genres}
              setGenres={setGenres}
            />
          ) : null}
        </div>
      </Layout>
    </BookstoreProvider>
  );
};

export default AdminAddBook;
