import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {adminService} from "service/Admin.service";
import styles from "./SelectAuthor.module.css";


const SelectAuthor = ({ setAuthor, author }) => {
  const { data } = useQuery({
    queryKey: ["author"],
    queryFn: () => adminService.getAuthor(),
  });
  const [authors, setAuthors] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const selectedAuthor = (event) => {
    const selectedIndex = event.target.selectedIndex;
    setSelectedOptionIndex(selectedIndex);

    const newAuthor = {
      authorName: authors[selectedIndex]?.alias,
      id: authors[selectedIndex]?.id,
    };
    setAuthor(newAuthor);
  };

  useEffect(() => {
    if (data?.length) {
      const authorsWithNone = [...data];
      authorsWithNone.unshift({ name: "NONE", surname: "NONE" });
      setAuthors(authorsWithNone);
    }
  }, [data]);

  useEffect(() => {
    if (author) {
      const findAuthorIndexById = (id) => {
        return authors.findIndex((author) => author.id === id);
      };

      const index = findAuthorIndexById(author.id);
      setSelectedOptionIndex(index !== -1 ? index : 0);
    }
  }, [author, authors]);

  return (
    <select
      onChange={selectedAuthor}
      className={styles.input}
      value={selectedOptionIndex}
    >
      {authors?.map((item, i) => (
        <option className={styles.option} key={i} value={i}>
          {item.alias}
        </option>
      ))}
    </select>
  );
};

export default SelectAuthor;
