import React, { useEffect, useState } from "react";
import styles from "./AdminCreateAuthor.module.css";
import Button from "components/Base/Button/Button";
import Layout from "components/layout/Layout";
import Input from "components/Base/Input/Input";
import instance from "utils/axios";
import { baseUrl } from "config";
import Header from "components/header/Header";
import { errorCatch } from "utils/errorCatch";
import { setModal } from "store/modalStore/action";
import { useModalStore } from "providers/ModalStoreProvider";
import { isEmailValid } from "../../../utils/validation";


const AdminCreateAuthor = () => {
  const [data, setData] = useState({
    name: "",
    surname: "",
    fatherName: "",
    alias: "",
    birthDate: "",
    email: "",
    password: "",
  });
  const [, setModalStore] = useModalStore()
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if(!data.name || !data.surname || !data.alias || !data.email || !data.password){
      setIsDisabled(true)
    } else setIsDisabled(false)
  }, [data.alias, data.email, data.name, data.password, data.surname])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await instance.post(`${baseUrl}/admin/addAuthor`, data);
      setModalStore(setModal(errorCatch(res.data.message)))
      return res;
    } catch (error) {
      setModalStore(setModal(errorCatch(error)))
		}
  };
  return (
    <Layout>
      <div className={styles.wrapper}>
        <Header />
        <form className={styles.register} onSubmit={handleSubmit}>
          <h1>Создать автора</h1>
          <div className="input__container">
            <Input
              type={"text"}
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              placeholder={"E-mail*"}
            />
            {!isEmailValid(data.email) && <span>Введите правильный e-mail!</span>}
            <Input
              type={"text"}
              value={data.surname}
              onChange={(e) => setData({ ...data, surname: e.target.value })}
              placeholder={"Фамилия*"}
            />
            {!data.surname && <span>Поле не может быть пустым!</span>}

            <Input
              type={"text"}
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              placeholder={"Имя*"}
            />
            {!data.name && <span>Поле не может быть пустым!</span>}

            <Input
              type={"text"}
              value={data.fatherName}
              onChange={(e) => setData({ ...data, fatherName: e.target.value })}
              placeholder={"Отчество"}
            />

            <Input
              value={data.birthDate}
              type={"date"}
              placeholder={"*"}
              onChange={(e) => {
                setData({ ...data, birthDate: e.target.value });
              }}
            />
            {!data.birthDate && <span>Поле не может быть пустым!</span>}

            <Input
              type={"text"}
              value={data.alias}
              onChange={(e) => setData({ ...data, alias: e.target.value })}
              placeholder={"Псевдоним*"}
            />
            {!data.alias && <span>Поле не может быть пустым!</span>}

            <Input
              type={"password"}
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              placeholder={"Придумайте пароль*"}
            />
            {!data.password && <span>Поле не может быть пустым!</span>}
          </div>
          <Button type="submit" value={"Создать"} disabled={isDisabled}/>
        </form>
      </div>
    </Layout>
  );
};

export default AdminCreateAuthor;
