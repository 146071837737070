import React, { useState} from "react";
import styles from "./Rules.module.css";
import { Fab } from "@mui/material";
import { IoAdd } from "react-icons/io5";
import { RiDeleteBinFill } from "react-icons/ri";
import { useQuery } from "@tanstack/react-query";
import {ruleService} from "service/Rule.service";
import CreateRule from "./CreateRule/CreateRule";
import { checkRole } from "utils/checkRole";
import { queryClient } from "index";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "store/modalStore/action";
import { errorCatch } from "utils/errorCatch";


const Rules = () => {
  const [confirm, setConfirm] = useState(false)
  const [, setModalStore] = useModalStore()
  const { data: Rules } = useQuery({
    queryKey: ["rule"],
    queryFn: () => ruleService.getAllRule(),
  });
  const [isOpen, setIsOpen] = useState(false);
  const onClick = async (id) => {
    try {
      await ruleService.deleteRule(id);
      queryClient.invalidateQueries({ queryKey: ["rule"] });
      setModalStore(setModal("Правило удалено"))
    } catch (e) {
      setModalStore(setModal(errorCatch(e)))
    }
  };
  const isAdmin = checkRole();
  return (
    <div className={styles.wrapper}>
    {confirm ? <div className={styles.confirm}>
        <div className={styles.confirm_content}>
          <p>Вы уверены в удалении правила?</p>
          <div>
            <button className={styles.confirm_button} onClick={()=>onClick()}>Удалить</button>
            <button className={styles.confirm_button} onClick={()=> setConfirm(!confirm)}>Отмена</button>
          </div>
         </div> 
        </div>: ""}
      <div className={styles.content}>
        <div className={styles.main}>
          <h2>Правила публикаций авторских произведений на проекте Bookva</h2>
          {Rules?.map((item) => (
            <div className={styles.rule} key={item.id}>
              <div className={styles.header}>
                <h3>
                {item.title} 
                </h3>
                {isAdmin && (
                  <div
                    className={styles.remove}
                    onClick={() => onClick(item.id)}
                  >
                    <RiDeleteBinFill />
                  </div>
                )}
              </div>
              <ul>{item.body.length > 1 ? item.body.split("|").map((item) => <li key={item.lenhgt+item}>{item}</li>) : ""} </ul>
            </div>
          ))}
        </div>
      </div>
      {isAdmin && (
        <div className={styles.buttonFixed}>
          <Fab aria-label="add" onClick={() => setIsOpen(true)}>
            <IoAdd />
          </Fab>
        </div>
      )}
      {isOpen && <CreateRule setState={setIsOpen} />}
    </div>
  );
};

export default Rules;
