import { createContext, useState } from "react";

export const NavModalContext = createContext({});

const NavModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <NavModalContext.Provider
      value={{ isOpen, setIsOpen }}
    >
			{children}
		</NavModalContext.Provider>
  );
};

export default NavModalProvider;
