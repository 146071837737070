import React, { useEffect, useState } from "react";
import styles from "./CreateRule.module.css";
import Input from "components/Base/Input/Input";
import Button from "components/Base/Button/Button";
import { useMutation } from "@tanstack/react-query";
import {ruleService} from "service/Rule.service";
import { IoMdClose } from "react-icons/io";
import { queryClient } from "index";
const CreateRule = ({ setState }) => {
  const [data, setData] = useState({
    index: "",
    title: "",
    body: "",
  });
  const {
    mutate,
    data: mutatenData,
    isSuccess,
  } = useMutation({
    mutationFn: () => ruleService.createRule({ ...data }),
  });
  useEffect(() => {
    if (mutatenData) {
      setState(false);
			queryClient.invalidateQueries({ queryKey: ["rule"] });
    }
  }, [mutatenData, isSuccess, setState]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <IoMdClose className={styles.close} onClick={() => setState(false)} />
        <div className={styles.inputs}>
          <Input
            placeholder={"Номер правила*"}
            value={data.index}
            onChange={(e) => setData({ ...data, index: e.target.value })}
          />
          <Input
            placeholder={"Название*"}
            value={data.title}
            onChange={(e) => setData({ ...data, title: e.target.value })}
          />
          <textarea
            cols={"30"}
            rows={"10"}
            className={styles.textarea}
            placeholder={"Текст*"}
            value={data.body}
            onChange={(e) => setData({ ...data, body: e.target.value })}
          />
        </div>
        <Button onClick={() => mutate()} value={"Создать правило"} />
      </div>
    </div>
  );
};

export default CreateRule;
