import instance from "../utils/axios";

class AuthorService {
  async getAuthorData() {
    const { data } = await instance.get("/author/get/getMe");
    return data;
  }
  async getAuthorById(id) {
    const { data } = await instance.get(`/author/byId/${id}`);
    return data;
  }
  async setAuthorProfile(profile) {
    const { data } = await instance.put("/author/edit", {
      ...profile,
    });
    return data;
  }
}

export const authorService =  new AuthorService();
